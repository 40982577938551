import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import ThemeProvider from './components/theme';
import routes, { renderRoutes } from './routes';
import { AuthProvider } from './contexts/JWTAuthContext';
import './index.scss';
const App = () => {
  return (
    <>
      <ThemeProvider>
        <BrowserRouter>
          <AuthProvider>{renderRoutes(routes)}</AuthProvider>
        </BrowserRouter>
      </ThemeProvider>
    </>
  );
};

export default App;
