import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
import axios from './../utilities/axios';
import { openSnackBar } from './snackbar';
import { useHistory } from 'react-router-dom';
const initialState = {
  registerstatus: '',
  menuCollapse: false,
};
export const register = createAsyncThunk('register/postregister', async (user, thunkAPI) => {
  let data;
  // const history=useHistory()
  try {
    const response = await axios.post(`/register`, user);
    data = await response.data;
    if ((response.status = 200)) {
      thunkAPI.dispatch(openSnackBar({ status: 'success', msg: 'you can login now' }));
      //   history.push('/login');
      return data;
    }

    throw new Error(response.statusText);
  } catch (err) {
    thunkAPI.dispatch(openSnackBar({ status: 'error', msg: err.message }));
    return Promise.reject(err.message ? err.message : data?.message);
  }
});
const registerSlice = createSlice({
  name: 'register',
  initialState,
  reducers: {
    changeState(state) {
      state.menuCollapse = !state.menuCollapse;
    },
  },
  extraReducers: {
    [register.pending]: (state) => {
      state.registerstatus = 'loading';
    },
    [register.fulfilled]: (state, action) => {
      state.registerstatus = 'succeeded';
    },
    [register.rejected]: (state, action) => {
      state.registerstatus = 'failed';
    },
  },
});

export const reducer = registerSlice.reducer;
export default registerSlice;
