import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
import axios from './../utilities/axios';
import { openSnackBar } from './snackbar';
const initialState = {
  players: [],
  playerstotalDocs: [],
  status: 'idle',
};
export const fetchUsers = createAsyncThunk('users/fetchUsers', async (pagination, thunkAPI) => {
  let data;
  try {
    const response = await axios.get(`/users${pagination}`);
    data = await response.data;
    if ((response.status = 200)) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    return Promise.reject(err.message ? err.message : data?.message);
  }
});
export const createUsers = createAsyncThunk('users/createUsers', async (user) => {
  let data;
  try {
    const response = await axios.post(`/users`, user);
    data = await response.data;
    if ((response.status = 200)) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    return Promise.reject(err.message ? err.message : data?.message);
  }
});
export const updateUsers = createAsyncThunk('users/updateUsers', async (user, thunkAPI) => {
  let data;
  try {
    const response = await axios.put(`/users/${user.id}`, {
      name: user.name,
      lastName: user.lastName,
      status: user.status,
      work: user.work,
      tel: user.tel,
    });

    data = await response.data;
    if ((response.status = 200)) {
      thunkAPI.dispatch(fetchUsers(``));
      thunkAPI.dispatch(openSnackBar({ status: 'success', msg: 'player updated successfully' }));
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    thunkAPI.dispatch(openSnackBar({ status: 'error', msg: err.message }));
    return Promise.reject(err.message ? err.message : data?.message);
  }
});
export const deleteUser = createAsyncThunk('users/deleteUser', async (user, thunkAPI) => {
  let data;
  try {
    const response = await axios.delete(`/users/${user.id}`);
    data = await response.data;
    if ((response.status = 200)) {
      thunkAPI.dispatch(fetchUsers(``));
      thunkAPI.dispatch(openSnackBar({ status: 'success', msg: 'player deleted successfully' }));
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    thunkAPI.dispatch(openSnackBar({ status: 'error', msg: err.message }));
    return Promise.reject(err.message ? err.message : data?.message);
  }
});
const playersSlice = createSlice({
  name: 'players',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchUsers.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchUsers.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.playerstotalDocs = action.payload.meta;
      state.players = action.payload.data;
    },
    [fetchUsers.rejected]: (state, action) => {
      state.status = 'failed';
    },

    [createUsers.pending]: (state) => {
      state.status = 'loading';
    },
    [createUsers.fulfilled]: (state, action) => {
      state.status = 'succeeded';

      state.players.push(action.payload.data);
    },
    [createUsers.rejected]: (state, action) => {
      state.status = 'failed';
    },

    [deleteUser.pending]: (state, action) => {
      state.status = 'loading';
    },
    [deleteUser.fulfilled]: (state, action) => {
      state.status = 'succeeded';
    },
    [deleteUser.rejected]: (state, action) => {
      state.status = 'failed';
    },
    [updateUsers.pending]: (state) => {
      state.status = 'loading';
    },
    [updateUsers.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      let Updateditem = state.players.find((id) => id._id === action.payload._id);
      Updateditem = action.payload.data;
    },
    [updateUsers.rejected]: (state, action) => {
      state.status = 'failed';
    },
  },
});
export const { matchesevents } = playersSlice.actions;

export const reducer = playersSlice.reducer;
export default playersSlice;
