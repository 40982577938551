import { combineReducers } from '@reduxjs/toolkit';
import { reducer as registerReducer } from './../slices/register';
import { reducer as matchesReducer } from './../slices/matches';
import { reducer as userReducer } from './../slices/user';
import { reducer as snackbarReducer } from './../slices/snackbar';
import { reducer as stadiumReducer } from './../slices/stadiums';
import { reducer as playersReducer } from './../slices/palyers';
import { reducer as rulesReducer } from './../slices/rules';
const combinedReducer = combineReducers({
  register: registerReducer,
  stadiums: stadiumReducer,
  matches: matchesReducer,
  snackbar: snackbarReducer,
  players: playersReducer,
  rule: rulesReducer,
  user: userReducer,
});
const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    state = undefined;
  }
  return combinedReducer(state, action);
};
export default rootReducer;
