import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
import axios from './../utilities/axios';
import { openSnackBar } from './snackbar';
const initialState = {
  rules: [],
  CreateRuleStatus: 'idle',
  FetchStatusRules: 'idle',
  deleteRuleStatus: 'idle',
};

export const fetchRules = createAsyncThunk('rules/fetchRules', async (content, thunkAPI) => {
  let data;
  try {
    const response = await axios.get(`/rules`);
    data = await response.data;
    if ((response.status = 200)) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    return Promise.reject(err.message ? err.message : data?.message);
  }
});
export const createRule = createAsyncThunk('rules/createRule', async (content, thunkAPI) => {
  let data;
  try {
    const response = await axios.post(`/rules`, content);
    data = await response.data;
    if ((response.status = 200)) {
      thunkAPI.dispatch(openSnackBar({ status: 'success', msg: 'Rule created successfully ' }));
      thunkAPI.dispatch(fetchRules());
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    thunkAPI.dispatch(openSnackBar({ status: 'error', msg: err.message }));
    return Promise.reject(err.message ? err.message : data?.message);
  }
});
export const deleteRule = createAsyncThunk('rules/deleteRule', async (id, thunkAPI) => {
  let data;
  try {
    const response = await axios.delete(`/rules/${id}`);
    data = await response.data;
    if ((response.status = 200)) {
      thunkAPI.dispatch(openSnackBar({ status: 'success', msg: 'Rule deleted successfully ' }));
      thunkAPI.dispatch(fetchRules());
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    thunkAPI.dispatch(openSnackBar({ status: 'error', msg: err.message }));
    return Promise.reject(err.message ? err.message : data?.message);
  }
});
export const updateRule = createAsyncThunk('rules/updateRule', async (content, thunkAPI) => {
  let data;
  try {
    const response = await axios.put(`/rules/${content.id}`, { content: content.content });
    data = await response.data;
    if ((response.status = 200)) {
      thunkAPI.dispatch(openSnackBar({ status: 'success', msg: 'Rule updated successfully ' }));
      thunkAPI.dispatch(fetchRules());
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    thunkAPI.dispatch(openSnackBar({ status: 'error', msg: err.message }));
    return Promise.reject(err.message ? err.message : data?.message);
  }
});

const ruleSlice = createSlice({
  name: 'rule',
  initialState,
  reducers: {},
  extraReducers: {
    [createRule.pending]: (state) => {
      state.CreateRuleStatus = 'loading';
    },
    [createRule.fulfilled]: (state, action) => {
      state.CreateRuleStatus = 'succeeded';
    },
    [createRule.rejected]: (state, action) => {
      state.CreateRuleStatus = 'failed';
    },
    [deleteRule.pending]: (state) => {
      state.deleteRuleStatus = 'loading';
    },
    [deleteRule.fulfilled]: (state, action) => {
      state.deleteRuleStatus = 'succeeded';
    },
    [deleteRule.rejected]: (state, action) => {
      state.deleteRuleStatus = 'failed';
    },
    [fetchRules.pending]: (state) => {
      state.FetchStatusRules = 'loading';
    },
    [fetchRules.fulfilled]: (state, action) => {
      state.FetchStatusRules = 'succeeded';
      state.rules = action.payload.data;
    },
    [fetchRules.rejected]: (state, action) => {
      state.FetchStatusRules = 'failed';
    },
  },
});
export const reducer = ruleSlice.reducer;
export default ruleSlice;
