import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchMatches } from './matches';
import { openSnackBar } from './snackbar';
import axios from './../utilities/axios';
const initialState = {
  stadiums: [],
  status: 'idle',
  stadiumdata: [],
  totalDocs: [],
  stadiumDeleteStatus: 'idle',
  stadiumPutStatus: 'idle',
  stadiumPostStatus: 'idle',
  stadiumFetcherror: null,
  stadiumPostError: null,
  stadiumputError: null,
  stadiumDeleteError: null,
};
export const fetchstadiums = createAsyncThunk('stadiums/fetchstadiums', async (query, thunkAPI) => {
  let data;
  try {
    const response = await axios.get(`/stadiums/${query}`);
    data = await response.data;
    if ((response.status = 200)) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    return Promise.reject(err.message ? err.message : data?.message);
  }
});
export const createstadiums = createAsyncThunk(
  'stadiums/createstadiums',
  async (stadium, thunkAPI) => {
    let data;
    try {
      const response = await axios.post(`/stadiums`, stadium);
      data = await response.data;
      if ((response.status = 200)) {
        thunkAPI.dispatch(openSnackBar({ status: 'success', msg: 'stadium created successfully' }));
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      thunkAPI.dispatch(openSnackBar({ status: 'error', msg: err.message }));
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const updatestadiums = createAsyncThunk(
  'stadiums/updatestadiums',
  async (body, thunkAPI) => {
    let data;
    try {
      const response = await axios.put(`/stadiums/${body.id}`, body);
      data = await response.data;
      if ((response.status = 200)) {
        thunkAPI.dispatch(openSnackBar({ status: 'success', msg: 'stadium updated successfully' }));
        thunkAPI.dispatch(fetchstadiums(''));
        thunkAPI.dispatch(fetchMatches(''));
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      thunkAPI.dispatch(openSnackBar({ status: 'success', msg: err.message }));
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const deletestadiums = createAsyncThunk('stadiums/deletestadiums', async (id, thunkAPI) => {
  let data;
  try {
    const response = await axios.delete(`/stadiums/${id}`);
    data = await response.data;
    if ((response.status = 200)) {
      thunkAPI.dispatch(openSnackBar({ status: 'success', msg: 'stadium deleted successfully' }));
      thunkAPI.dispatch(fetchstadiums(''));
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    return Promise.reject(err.message ? err.message : data?.message);
  }
});
const stadiumsSlice = createSlice({
  name: 'stadiums',
  initialState,
  reducers: {
    getstadiumLocalization: (state, action) => {
      state.stadiumLocalization = action.payload;
    },
    getStadiumData: (state, action) => {
      state.stadiumdata = action.payload;
    },
  },
  extraReducers: {
    [fetchstadiums.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchstadiums.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.stadiums = action.payload.data;
      state.totalDocs = state.stadiums.length;
    },
    [fetchstadiums.rejected]: (state, action) => {
      state.status = 'failed';
    },

    [createstadiums.pending]: (state) => {
      state.stadiumPostStatus = 'loading';
    },
    [createstadiums.fulfilled]: (state, action) => {
      state.stadiumPostStatus = 'succeeded';
      state.stadiums.push(action.payload.data);
    },
    [createstadiums.rejected]: (state, action) => {
      state.stadiumPostStatus = 'failed';
    },

    [deletestadiums.pending]: (state, action) => {
      state.stadiumDeleteStatus = 'loading';
    },
    [deletestadiums.fulfilled]: (state, action) => {
      state.stadiumDeleteStatus = 'succeeded';
      state.stadiums.filter((item) => item._id !== action.payload);
      console.log();
    },
    [deletestadiums.rejected]: (state, action) => {
      state.stadiumDeleteStatus = 'failed';
    },
    [updatestadiums.pending]: (state) => {
      state.stadiumPutStatus = 'loading';
    },
    [updatestadiums.fulfilled]: (state, action) => {
      state.stadiumPutStatus = 'succeeded';
      let Updateditem = state.stadiums.find((id) => id._id === action.payload._id);
      Updateditem = action.payload.data;
    },
    [updatestadiums.rejected]: (state, action) => {
      state.stadiumPutStatus = 'failed';
    },
  },
});
export const { getstadiumLocalization, getStadiumData } = stadiumsSlice.actions;
export const reducer = stadiumsSlice.reducer;
export default stadiumsSlice;
