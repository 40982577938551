import React from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
const Switch = ({ children }) => {
  const { registerstatus } = useSelector((state) => state.register);
  if (registerstatus === 'succeeded') {
    return <Redirect to="/login" />;
  }
  return <div>{children}</div>;
};

export default Switch;
