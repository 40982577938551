import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from './../utilities/axios';
import { fetchUser } from './user';
const initialState = {
  matches: [],
  fakematches: [],
  stadiumdata: [],
  participants: [],
  matchLocalization: [],
  userMatches: [],
  status: 'idle',
  matchDeleteStatus: 'idle',
  matchPutStatus: 'idle',
  matchPostStatus: 'idle',
  matchFetcherror: null,
  matchPostError: null,
  matchputError: null,
  matchDeleteError: null,
};
export const fetchMatches = createAsyncThunk('matches/fetchMatches', async (thunkAPI) => {
  let data;
  try {
    const response = await axios.get(`/matches`);
    data = await response.data;
    if ((response.status = 200)) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    return Promise.reject(err.message ? err.message : data?.message);
  }
});
export const createMatches = createAsyncThunk('matches/createMatches', async (match, thunkAPI) => {
  let data;
  try {
    const response = await axios.post(`/matches`, match);
    data = await response.data;
    if ((response.status = 200)) {
      thunkAPI.dispatch(fetchMatches());
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    return Promise.reject(err.message ? err.message : data?.message);
  }
});
export const GetMyMatches = createAsyncThunk('matches/GetMyMatches', async (id, thunkAPI) => {
  let data;
  try {
    const response = await axios.get(`/matches/${id}/myMatches`);
    data = await response.data;
    if ((response.status = 200)) {
      thunkAPI.dispatch(fetchUser());
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    return Promise.reject(err.message ? err.message : data?.message);
  }
});
export const ParticipateInMatch = createAsyncThunk(
  'matches/ParticipateInMatch',
  async (match, thunkAPI) => {
    let data;
    try {
      const response = await axios.post(`/matches/participate/${match.idMatch}`);
      data = await response.data;
      if ((response.status = 200)) {
        thunkAPI.dispatch(fetchMatches());
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const cancelPartcipation = createAsyncThunk(
  'matches/cancelPartcipation',
  async (match, thunkAPI) => {
    let data;
    try {
      const response = await axios.post(`matches/cancelparticipation/${match.idMatch}`, {
        _id: match._id,
      });
      data = await response.data;
      if ((response.status = 200)) {
        thunkAPI.dispatch(fetchMatches());
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const updateMatches = createAsyncThunk('matches/updateMatches', async (body, thunkAPI) => {
  let data;
  try {
    const response = await axios.put(`/matches/${body.id}`, body);
    data = await response.data;
    if ((response.status = 200)) {
      thunkAPI.dispatch(fetchMatches());
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    return Promise.reject(err.message ? err.message : data?.message);
  }
});
export const deleteMatches = createAsyncThunk('matches/deletematches', async (id, thunkAPI) => {
  let data;
  try {
    const response = await axios.delete(`/matches/${id}`);
    data = await response.data;

    if ((response.status = 200)) {
      thunkAPI.dispatch(fetchMatches());
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    return Promise.reject(err.message ? err.message : data?.message);
  }
});
const matchesSlice = createSlice({
  name: 'matches',
  initialState,
  reducers: {
    getMatchLocalization: (state, action) => {
      state.matchLocalization = action.payload;
    },
    getStadiumData: (state, action) => {
      state.stadiumdata = action.payload;
    },
  },
  extraReducers: {
    [fetchMatches.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchMatches.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.matches = action.payload.data;
    },
    [fetchMatches.rejected]: (state, action) => {
      state.status = 'failed';
    },

    [GetMyMatches.pending]: (state) => {
      state.status = 'loading';
    },
    [GetMyMatches.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.userMatches = action.payload.data;
    },
    [GetMyMatches.rejected]: (state, action) => {
      state.status = 'failed';
    },
    [createMatches.pending]: (state) => {
      state.matchPostStatus = 'loading';
    },
    [createMatches.fulfilled]: (state, action) => {
      state.matchPostStatus = 'succeeded';
      state.matches.push(action.payload.data);
    },
    [createMatches.rejected]: (state, action) => {
      state.matchPostStatus = 'failed';
    },
    [ParticipateInMatch.pending]: (state) => {
      state.matchPostStatus = 'loading';
    },
    [ParticipateInMatch.fulfilled]: (state, action) => {
      state.matchPostStatus = 'succeeded';
      state.participants = action.payload.data.participants;
    },
    [ParticipateInMatch.rejected]: (state, action) => {
      state.matchPostStatus = 'failed';
    },
    [deleteMatches.pending]: (state, action) => {
      state.matchDeleteStatus = 'loading';
    },
    [deleteMatches.fulfilled]: (state, action) => {
      state.matchDeleteStatus = 'succeeded';
      state.matches.filter((item) => item._id !== action.payload);
    },
    [deleteMatches.rejected]: (state, action) => {
      state.matchDeleteStatus = 'failed';
    },
    [updateMatches.pending]: (state) => {
      state.matchPutStatus = 'loading';
    },
    [updateMatches.fulfilled]: (state, action) => {
      state.matchPutStatus = 'succeeded';
      let Updateditem = state.matches.find((id) => id._id === action.payload._id);
      Updateditem = action.payload.data;
    },
    [updateMatches.rejected]: (state, action) => {
      state.matchPutStatus = 'failed';
    },
  },
});
export const { getMatchLocalization, getStadiumData } = matchesSlice.actions;
export const reducer = matchesSlice.reducer;
export default matchesSlice;
