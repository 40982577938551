import React from 'react';
import Iconify from '../../components/Iconify';
import staduim from './../../assets/img/stadium.png';
import stadiums from './../../assets/icons/stadiums.svg';
const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;
import RuleIcon from '@mui/icons-material/Rule';
const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: 'Players',
    path: '/players',
    icon: getIcon('eva:people-fill'),
  },

  {
    title: 'match event',
    path: '/matches',
    icon: getIcon('emojione:soccer-ball'),
  },
  {
    title: 'Stadiums',
    path: '/stadiums',
    icon: <img src={stadiums} style={{ width: '29px', height: '32px' }} />,
  },
  {
    title: 'Rules',
    path: '/create-rule',
    icon: <RuleIcon />,
  },
];

export default navConfig;
