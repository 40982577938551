import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Loader from './components/Loader/Loader';
import AuthGuard from './components/AuthGuard';
import GuestGuard from './components/GuestGuard';
import DashboardLayout from './layouts/DashboardLayout';
import SwitchGuard from './components/SwitchGuard';
import SwitchUser from './pages/Auth/Switch';
export const renderRoutes = (routes = []) => (
  <Suspense fallback={<Loader />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;
        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes ? renderRoutes(route.routes) : <Component {...props} />}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);
const routes = [
  {
    exact: true,
    path: '/login',
    guard: GuestGuard,
    component: lazy(() => import('./pages/Auth/LoginView/login')),
  },

  {
    exact: true,
    path: '/register',
    guard: SwitchGuard,
    component: lazy(() => import('./pages/Auth/RegisterView/Register')),
  },

  {
    path: '/',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/dashboard',
        component: lazy(() => import('./pages/Dashboard/Dashboard')),
      },
      {
        exact: true,
        path: '/edit-profile',
        component: lazy(() => import('./pages/EditProfile/EditProfile')),
      },
      {
        exact: true,
        path: '/edit-password',
        component: lazy(() => import('./pages/EditPassword/EditPassword')),
      },
      {
        exact: true,
        path: '/players',
        component: lazy(() => import('./pages/Players/Players')),
      },
      {
        exact: true,
        path: '/matches',
        component: lazy(() => import('./pages/Matches/MatchesEvents')),
      },
      {
        exact: true,
        path: '/createStaduim',
        component: lazy(() => import('./pages/StaduimCreation/StadiumCreation')),
      },
      {
        exact: true,
        path: '/stadiums',
        component: lazy(() => import('./pages/Stadiums/stadiums')),
      },
      {
        exact: true,
        path: '/create-rule',
        component: lazy(() => import('./pages/CreateRule/CreateRule')),
      },
      {
        exact: true,
        path: '/',
        component: () => <Redirect to="/dashboard" />,
      },
    ],
  },
];
export default routes;
