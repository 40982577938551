import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom';
import { alpha, useTheme, styled } from '@mui/material/styles';
import { Box, List, Collapse, ListItemText, ListItemIcon, ListItemButton } from '@mui/material';
import Iconify from './Iconify';
import { useSelector } from 'react-redux';
const ListItemStyle = styled((props) => <ListItemButton disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.body2,
    height: 48,
    position: 'relative',
    textTransform: 'capitalize',
    color: theme.palette.text.secondary,
    borderRadius: theme.shape.borderRadius,
  })
);
const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  color: 'inherit',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

function NavItem({ item, active }) {
  const theme = useTheme();
  const isActiveRoot = active;
  const { title, path, icon, info, children } = item;
  const [openNavSection, setOpenNavSection] = useState(isActiveRoot);
  const handleOpenNavSection = () => {
    setOpenNavSection((prev) => !prev);
  };
  const activeRootStyle = {
    color: 'primary.main',
    fontWeight: 'fontWeightMedium',
    bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
  };

  const activeSubStyle = {
    color: 'text.primary',
    fontWeight: 'fontWeightMedium',
  };

  if (children) {
    return (
      <>
        <ListItemStyle
          onClick={handleOpenNavSection}
          sx={{
            ...(isActiveRoot && activeRootStyle),
          }}
        >
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
          <ListItemText disableTypography primary={title} />
          {info && info}
          <Iconify
            icon={openNavSection ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
            sx={{ width: 16, height: 16, ml: 1 }}
          />
        </ListItemStyle>
        <Collapse in={openNavSection} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((item) => {
              const { title, path } = item;
              const isActiveSub = active(path);
              return (
                <ListItemStyle
                  key={title}
                  component={RouterLink}
                  to={path}
                  sx={{
                    ...(isActiveSub && activeSubStyle),
                  }}
                >
                  <ListItemIconStyle>
                    <Box
                      component="span"
                      sx={{
                        width: 4,
                        height: 4,
                        display: 'flex',
                        borderRadius: '50%',
                        alignItems: 'center',
                        justifyContent: 'center',
                        bgcolor: 'text.disabled',
                        transition: (theme) => theme.transitions.create('transform'),
                        ...(isActiveSub && {
                          transform: 'scale(2)',
                          bgcolor: 'primary.main',
                        }),
                      }}
                    />
                  </ListItemIconStyle>
                  <ListItemText disableTypography primary={title} />
                </ListItemStyle>
              );
            })}
          </List>
        </Collapse>
      </>
    );
  }

  return (
    <ListItemStyle
      component={RouterLink}
      to={path}
      sx={{
        ...(isActiveRoot && activeRootStyle),
      }}
    >
      <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
      <ListItemText disableTypography primary={title} />
      {info && info}
    </ListItemStyle>
  );
}
function NavSection({ navConfig, ...other }) {
  const { user } = useSelector((state) => state.user);
  const { pathname } = useLocation();
  return (
    <Box {...other} style={{ marginTop: '64px' }}>
      <List disablePadding sx={{ p: 1 }}>
        {user.role === 'admin' ? (
          <>
            <NavItem item={navConfig[0]} active={navConfig[0].path === pathname ? true : false} />
            <NavItem item={navConfig[1]} active={navConfig[1].path === pathname ? true : false} />
            <NavItem item={navConfig[2]} active={navConfig[2].path === pathname ? true : false} />
            <NavItem item={navConfig[3]} active={navConfig[3].path === pathname ? true : false} />
            <NavItem item={navConfig[4]} active={navConfig[4].path === pathname ? true : false} />
          </>
        ) : user.role === 'user' ? (
          <>
            <NavItem item={navConfig[0]} active={navConfig[0].path === pathname ? true : false} />
            <NavItem item={navConfig[2]} active={navConfig[2].path === pathname ? true : false} />
          </>
        ) : (
          ''
        )}
      </List>
    </Box>
  );
}

export default NavSection;
