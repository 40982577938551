import React, { useRef, useEffect, useState } from 'react';
import { fetchUser } from './../../slices/user';
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, MenuItem, Avatar, IconButton } from '@mui/material';
import MenuPopover from '../../components/MenuPopover';
import { useSelector, useDispatch } from 'react-redux';
import useAuth from './../../hooks/useAuth';
import stringAvatar from './../../utilities/StringToColor';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
export default function AccountPopover() {
  const dispatch = useDispatch();
  const { logout } = useAuth();
  const history = useHistory();
  const anchorRef = useRef(null);
  const [openAccount, setOpenAccount] = useState(null);
  const handleOpenAccount = (event) => {
    setOpenAccount(event.currentTarget);
  };
  const { user } = useSelector((state) => state.user);
  useEffect(() => {
    if (user.length === 0) dispatch(fetchUser());
  }, [dispatch]);
  const handleClose = () => {
    setOpenAccount(null);
  };
  const handleLogout = async () => {
    try {
      await logout();
      dispatch({ type: 'LOGOUT' });
      //   history.push('/login');
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpenAccount}
        sx={{
          p: 0,
          ...(openAccount && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar alt={user?.name} {...stringAvatar(user?.name, user?.lastName)} />
      </IconButton>
      <MenuPopover
        open={Boolean(openAccount)}
        anchorEl={openAccount}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user.name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user.email}
          </Typography>
        </Box>
        <Divider sx={{ borderStyle: 'dashed' }} />
        <Link to="/edit-profile">
          <Typography variant="body2" sx={{ color: 'text.secondary', marginLeft: '22px' }} noWrap>
            Edit profile{' '}
          </Typography>
        </Link>
        <Divider sx={{ borderStyle: 'dashed' }} />
        <Link to="/edit-password">
          <Typography variant="body2" sx={{ color: 'text.secondary', marginLeft: '22px' }} noWrap>
            Edit password{' '}
          </Typography>
        </Link>
        <Divider sx={{ borderStyle: 'dashed' }} />
        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </MenuPopover>
    </>
  );
}
