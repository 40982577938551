import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../utilities/axios';
import { openSnackBar } from './snackbar';
const initialState = {
  user: [],
  status: 'idle',
  updatemestatus: 'idle',
  updatestatus: 'idle',
  error: null,
};
export const fetchUser = createAsyncThunk('user/fetchUser', async () => {
  let data;
  try {
    const response = await axios.get(`/me`);
    data = await response.data;

    if ((response.status = 200)) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    return Promise.reject(err.message ? err.message : data?.message);
  }
});
export const UpdateMe = createAsyncThunk('user/UpdateMe', async (me, thunkAPI) => {
  let data;
  try {
    const response = await axios.put(`/updateMe`, me);
    data = await response.data;
    if ((response.status = 200)) {
      thunkAPI.dispatch(openSnackBar({ status: 'success', msg: 'user updated successfully' }));
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    thunkAPI.dispatch(openSnackBar({ status: 'error', msg: err.message }));
    return Promise.reject(err.message ? err.message : data?.message);
  }
});
export const UpdateMyPasswords = createAsyncThunk(
  'user/UpdateMyPassword',
  async (password, thunkAPI) => {
    let data;
    try {
      const response = await axios.put(`/updateMyPassword`, password);
      data = await response.data;
      if ((response.status = 200)) {
        thunkAPI.dispatch(
          openSnackBar({ status: 'success', msg: 'password updated successfully' })
        );
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      thunkAPI.dispatch(openSnackBar({ status: 'error', msg: err.message }));
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchUser.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchUser.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.user = action.payload.data;
    },
    [fetchUser.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
  },
});
export const reducer = slice.reducer;
export default slice;
