import { dashToEmptySpace } from './dashToEmptySpace';
export default {
  APPBAR_MOBILE: 64,
  APPBAR_DESKTOP: 92,
  DRAWER_WIDTH: 280,
  APP_BAR_MOBILE: 64,
  APP_BAR_DESKTOP: 92,
  TABLE_HEAD: [
    { id: 'name', label: 'Name', alignRight: false },
    { id: 'Tel', label: 'Tel', alignRight: false },
    { id: 'role', label: 'Role', alignRight: false },
    { id: 'email', label: 'Email', alignRight: false },
    { id: 'status', label: 'Status', alignRight: false },
    { id: 'action', label: 'action', alignRight: false },
  ],
  STADIUMS_TABLE_HEAD: [
    { id: 'name', label: 'Name', alignRight: false },
    { id: 'latitude', label: 'Latitude', alignRight: false },
    { id: 'longitude', label: 'Longitude', alignRight: false },
    { id: 'players', label: 'Players', alignRight: false },
    { id: 'responsiblename', label: 'Responsible Name', alignRight: false },
    { id: 'responsiblephone', label: 'Responsible phone', alignRight: false },
    { id: 'actions', label: 'Action', alignRight: false },
  ],
  TEXTFIELD_LABELS: [
    { label: 'FirstName', FirstName: '' },
    { label: 'LastName', Name: '' },
    { label: 'Role', Role: '' },
    { label: 'Tel', Tel: '' },
  ],
  MENU_OPTIONS: [
    {
      label: 'Home',
      icon: 'eva:home-fill',
      linkTo: '/',
    },
    {
      label: 'Profile',
      icon: 'eva:person-fill',
      linkTo: '#',
    },
    {
      label: 'Settings',
      icon: 'eva:settings-2-fill',
      linkTo: '#',
    },
  ],
  options: [
    { value: 'active', label: 'active' },
    { value: 'banned', label: 'banned' },
  ],
  staduimOptions: [
    {
      value: {
        id: 1,
        staduim: 'balbouli',
        latitude: 35.827512029279646,
        longitude: 10.601221150859262,
      },
      label: 'balbouli',
    },
    {
      value: {
        id: 2,
        staduim: 'ooredoo',
        latitude: 35.84300814491566,
        longitude: 10.61103342870298,
      },
      label: 'ooredoo',
    },
    {
      value: {
        id: 3,
        staduim: 'versaille',
        latitude: 35.87080947720847,
        longitude: 10.585903010752812,
      },
      label: 'versaille',
    },
  ],

  rules: [
    {
      id: 1,
      rule: " Time Tuesday at 19 o'clock Arrive 10 minutes before in case of delay Notify by phone 52156948",
    },
    { id: 2, rule: 'The official at the stadium is called (Grandy), tel: 50625625.' },
    {
      id: 3,
      rule: 'A new list that starts on Friday and ends on Sunday (Confirmation) on Monday.',
    },
  ],
};
