import React from 'react';
import { alpha, styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton } from '@mui/material';
import Iconify from '../../components/Iconify';
import AccountPopover from './AccountPopover';
import data from './../../utilities/constants';
const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none!important',
  backgroundColor: 'transparent!important',
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${data.DRAWER_WIDTH + 1}px)`,
  },
}));
const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: data.APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: data.APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));
export default function DashboardNavbar({ onOpenSidebar }) {
  return (
    <RootStyle>
      <ToolbarStyle>
        <IconButton
          onClick={onOpenSidebar}
          sx={{ mr: 1, color: 'text.primary', display: { lg: 'none' } }}
        >
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>
        <Box sx={{ flexGrow: 1 }} />
        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          <AccountPopover />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
